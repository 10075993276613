import React from 'react';
import useFetch from '../hooks/useFetch.js';
import './css/Blog.css';

const Blog = () => {
  const { data: posts, loading, error } = useFetch('/wordpress/wp-json/wp/v2/posts');

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error: {error.message}</p>;

  return (
    <div className="blog-container">
      <div className="blog-main">
        <h1>Blog</h1>
        <div className="blog-posts">
          {posts.map(post => (
            <div key={post.id} className="blog-card">
              {post.featured_media_url && (
                <img src={post.featured_media_url} alt={post.title.rendered} className="blog-image" />
              )}
              <div className="blog-content">
                <h2>{post.title.rendered}</h2>
                <p>{post.excerpt.rendered.replace(/(<([^>]+)>)/gi, '')}</p>
                <a href={`/blog/${post.slug}`} className="read-more">Read More</a>
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className="blog-sidebar">
        <img src="/images/mark-blue-circle.png" alt="CannabisMDTelemed Logo" className="sidebar-logo" />
        <a href="/book-appointment" className="book-appointment"><button className='ctabutton'>Book Appointment</button></a>
        <h3>Popular Posts</h3>
        <ul>
          {/* Assuming popular posts data is fetched and passed */}
          {posts.slice(0, 5).map(post => (
            <li key={post.id}>
              <a href={`/blog/${post.slug}`}>{post.title.rendered}</a>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default Blog;
// import React from 'react';
// import useFetch from '../hooks/useFetch.js';

// const Blog = () => {
//   const { data: posts, loading, error } = useFetch('/wordpress/wp-json/wp/v2/posts');

//   if (loading) return <p>Loading...</p>;
//   if (error) return <p>Error: {error.message}</p>;

//   return (
//     <div>
//       <h1>Blog</h1>
//       {posts.map(post => (
//         <div key={post.id}>
//           <h2>{post.title.rendered}</h2>
//           <div dangerouslySetInnerHTML={{ __html: post.content.rendered }} />
//         </div>
//       ))}
//     </div>
//   );
// };

// export default Blog;
