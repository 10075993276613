// Membership.js
import React from 'react';
import './css/Membership.css';

const memberships = [
  { src: '/images/vcia.png', alt: 'VCIA', link: 'https://virginiacannabisindustry.com/' },
  { src: '/images/socc.png', alt: 'SOCC', link: 'https://www.cannabisclinicians.org/' },
  { src: '/images/tmci.png', alt: 'TMCI', link: 'https://themedicalcannabisinstitute.org/' },
  { src: '/images/norml.png', alt: 'NORML', link: 'https://www.vanorml.org/' }
];

const Membership = () => {
  return (
    <div className="membership-section">
      <div className="membership-text">
        <h2 className="membership-title">Proud</h2>
        <h2 className="membership-subtitle">Member Of</h2>
      </div>
      <div className="membership-logos">
        {memberships.map((membership, index) => (
          <a href={membership.link} key={index} target="_blank" rel="noopener noreferrer">
            <img src={membership.src} alt={membership.alt} className="membership-logo" />
          </a>
        ))}
      </div>
    </div>
  );
};

export default Membership;