// CTA.js
import React from 'react';
import './css/CTA.css';
// import CardsImage from './images/cardBunch.png'; // Adjust the path accordingly

const CTA = () => {
  return (
    <div className="cta-section">
      <div className="cta-dots"></div>
      <div className="cta-content">
        <div className="cta-text">
          <h2 className="cta-title"><span class="text-highlight-green">Book</span> An Appointment</h2>
          <p className="cta-description">
            Schedule Your Virtual Appointment With A Licensed Doctor By Clicking On The Appointment Type To Become Certified For Virginia’s Medical LeafRight Program. If You Feel Medical Marijuana Is Not Right For You At The End Of Your Appointment Then Let Us Know And We Will Issue A <strong>100% Refund!</strong>
          </p>
          <a href="https://cannabismdtelemed.com/appointments"><button className="ctabutton">Get Started</button></a>
        </div>
        <div className="cta-image">
          <img src="/images/cardBrunch.png" alt="Medical Cannabis ID Cards" />
        </div>
      </div>
      
    </div>
  );
};

export default CTA;