import React from 'react';
import useFetch from '../hooks/useFetch.js';
import './css/Blog.css';

const CannabisEducation = () => {
  const { data: strains, loading, error } = useFetch('/wordpress/wp-json/wp/v2/cannabis-strain?per_page=100');

  if (loading) return (
    <div className="loading">
      <p>Loading cannabis education...</p>
    </div>
  );
  
  if (error) return (
    <div className="error">
      <p>Error loading cannabis education: {error.message}</p>
    </div>
  );

  if (!strains || strains.length === 0) return (
    <div className="no-content">
      <p>No cannabis strains found.</p>
    </div>
  );

  return (
    <div className="blog-container">
      <div className="blog-main full-width">
        <h1>Cannabis Education</h1>
        <div className="blog-posts">
          {strains.map(strain => (
            <div key={strain.id} className="blog-card">
              {strain.featured_media && (
                <img 
                  src={strain._embedded?.['wp:featuredmedia']?.[0]?.source_url}
                  alt={strain.title.rendered}
                  className="blog-image"
                />
              )}
              <div className="blog-content">
                <h2>{strain.title.rendered}</h2>
                <div 
                  className="strain-content"
                  dangerouslySetInnerHTML={{ __html: strain.content.rendered }} 
                />
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default CannabisEducation;
