import React from 'react';
import Steps from './Steps';
import FAQPreview from './FAQPreview';
import { FAQData } from './FAQ';
import Membership from './Membership';
import TrustpilotReviews from './TrustpilotReviews';
import './css/HowToQualify.css';
import BenefitsCTA from './BenefitsCTA';
import WhyChooseUs from './WhyChooseUs';
import FindDoctorSection from './FindDoctorSection';
import BenefitsSection from './BenefitsSection';

function VirginiaDispensaryLocations() {
  return (
    <div className="virginia-dispensary-locations how-to-qualify" >
        <h1 style={{textAlign: 'center'}}>Virginia Dispensary Locations Near Me</h1>
        <h2 style={{textAlign: 'center'}}>Medical Cannabis Dispensary Locations in Virginia</h2>
        <div className="find-doctor-section">
          <div className="left-column">
            <div className="image-container">
              <img src="/images/Doctor_with_medical_marijuana.jpg" alt="Doctor with medical marijuana" className="background-image" />
              <div className="overlay">
                <h1 className="headline">Medical Marijuana Dispensaries Now Open in Virginia!</h1>
              </div>
            </div>
          </div>
          <div className="right-column">
            <div className="content">
              <div className="scrollable-content">
                <p>When patients want to purchase medical marijuana legally, they can visit any licensed Virginia Marijuana Dispensary. These state-regulated cannabis dispensaries opened in 2020 and have been serving residents of Virginia.</p>
                <p>This Virginia medical marijuana dispensary page goes over details about where you can find a Virginia cannabis dispensary near you. Some of the products carried at most marijuana dispensary locations include flower marijuana products, vapes, cartridges, edibles, extracts, concentrates, live rosin, tinctures, lotions, and more.</p>
                <p><a href="https://cannabismdtelemed.com/appointments" className="text-link">Get your Virginia medical marijuana certification or MMJ Card</a> so you can legally purchase cannabis products containing THC and CBD at licensed medical marijuana dispensary locations throughout VA.</p>
                <p>In order to enter a cannabis dispensary in Virginia so you can shop their products, you must have a recommendation from a Virginia marijuana doctor, which we can provide!</p>
                <p>If you are interested in staying up to date on the newest Virginia marijuana dispensary locations, then you can sign up for our newsletter or regularly check this page as we update it anytime a new cannabis dispensary location opens in Virginia.</p>
                <h3 style={{textAlign: 'center'}}>You Can Shop Online and Get Medical Marijuana Delivery in Virginia</h3>
                <p>Most patients seeking natural relief using medical marijuana will have the convenience of shopping for medical marijuana products online through the cannabis dispensary’s website. Most of the medical marijuana dispensaries in Virginia have their cannabis menus posted online for patients to browse and purchase medicinal marijuana products.</p>
                <p>Once you are certified by a Virginia marijuana doctor you will be able to immediately go to any Virginia medical marijuana dispensary location. You can also apply to receive your Virginia marijuana card with the state so you can purchase products at cannabis dispensary locations in other states including Washington DC. Many patients in Virginia use our online platform to see a marijuana doctor, receive their medical cannabis certification by email, register with the dispensary, and order products straight to their home all on the same day from the comfort of their home. Many cannabis dispensary locations in Virginia offer same-day delivery of marijuana products to residents of Virginia.</p>
                <FAQPreview faqData={FAQData} filterTags={['vadispense']} moreQuestionsLink="/faq" />
                <div className='va-loc-section'>
                  <h2>Virginia Locations</h2>
                  <h3>Area 1:</h3>
                  <p>The pharmaceutical processors for Area 1 is TBD.</p>
                  <h3>Area 2:</h3>
                  <p><a className='text-link' href="https://cannabismdtelemed.com/virginia-dispensary-locations/beyondhello-dispensary-manassas">Beyond/Hello  - Manassas, VA</a></p>
                  <p><a className='text-link' href="https://cannabismdtelemed.com/virginia-dispensary-locations/beyondhello-dispensary-sterling">Beyond/Hello  - Sterling, VA</a></p>
                  <p><a className='text-link' href="https://cannabismdtelemed.com/virginia-dispensary-locations/beyondhello-dispensary-alexandria">Beyond/Hello  - Alexandria, VA</a></p>
                  <p><a className='text-link' href="https://cannabismdtelemed.com/virginia-dispensary-locations/beyondhello-dispensary-fairfax">Beyond/Hello  - Fairfax, VA</a></p>
                  <p><a className='text-link' href="https://cannabismdtelemed.com/virginia-dispensary-locations/beyondhello-dispensary-arlington-va">Beyond/Hello  - Arlington, VA</a></p>
                  <p><a className='text-link' href="https://cannabismdtelemed.com/virginia-dispensary-locations/beyondhello-dispensary-woodbridge-va">Beyond/Hello  - Woodbridge, VA</a></p>
                  <h3>Area 3:</h3>
                  <p><a className='text-link' href="https://cannabismdtelemed.com/virginia-dispensary-locations/rise-dispensary-abingdon-virginia">Rise Dispensary - Abingdon, VA</a></p>
                  <p><a className='text-link' href="https://cannabismdtelemed.com/virginia-dispensary-locations/rise-dispensary-salem-virginia">Rise Dispensary - Salem, VA</a></p>
                  <p><a className='text-link' href="https://cannabismdtelemed.com/virginia-dispensary-locations/rise-medical-marijuana-dispensary-bristol-va">Rise Dispensary - Bristol, VA</a></p>
                  <h3>Area 4:</h3>
                  <p><a className='text-link' href="https://cannabismdtelemed.com/virginia-dispensary-locations/gleaf-medical-marijuana">gLeaf Medical Cannabis  - Richmond, VA</a></p>
                  <p><a className='text-link' href="https://cannabismdtelemed.com/virginia-dispensary-locations/gleaf-medical-cannabis">gLeaf Medical Cannabis  - Glen Allen, VA</a></p>
                  <p><a className='text-link' href="https://cannabismdtelemed.com/virginia-dispensary-locations/cannabist-medical-marijuana-carytown-virginia">Cannabist (in Carytown) - Richmond, VA</a></p>
                  <p><a className='text-link' href="https://cannabismdtelemed.com/virginia-dispensary-locations/cannabist-medical-marijuana-laburnum">Cannabist (S Laburnum Ave) - Henrico, VA</a></p>
                  <p><a className='text-link' href="https://cannabismdtelemed.com/virginia-dispensary-locations/gleaf-medical-cannabis-colonial-heights-va">gLeaf Medical Cannabis  - Colonial Heights, VA</a></p>
                  <h3>Area 5:</h3>
                  <p><a className='text-link' href="https://cannabismdtelemed.com/virginia-dispensary-locations/columbia-care">Cannabist - Portsmouth, VA</a></p>
                  <p><a className='text-link' href="https://cannabismdtelemed.com/virginia-dispensary-locations/cannabist-medical-cannabis">Cannabist - Virginia Beach, VA</a></p>
                  <p><a className='text-link' href="https://cannabismdtelemed.com/virginia-dispensary-locations/cannabist-medical-marijuana-williamsburg">Cannabist - Williamsburg, VA</a></p>
                  <p><a className='text-link' href="https://cannabismdtelemed.com/virginia-dispensary-locations/cannabist-medical-marijuana-norfolk-va">Cannabist - Norfolk, VA</a></p>
                  <p><a className='text-link' href="https://cannabismdtelemed.com/virginia-dispensary-locations/cannabist-medical-marijuana-hampton-va">Cannabist - Hampton, VA</a></p>
                  <h3>Area 6:</h3>
                  <p><a className='text-link' href="https://cannabismdtelemed.com/virginia-dispensary-locations/rise-dispensary-christiansburg-virginia">Rise - Christiansburg, VA</a></p>
                  <p><a className='text-link' href="https://cannabismdtelemed.com/virginia-dispensary-locations/rise-dispensary-lynchburg-virginia">Rise - Lynchburg, VA</a></p>
                  <p><a className='text-link' href="https://cannabismdtelemed.com/virginia-dispensary-locations/rise-medical-marijuana-dispensary-danville-va">Rise - Danville, VA</a></p>
                </div>
                <div className='button-container'><a href="https://cannabismdtelemed.com/appointments" className="cta-button">Get Approved Today</a></div>
              </div>
            </div>
          </div>
        </div>
      
        <Membership />
    </div>
  );
}

export default VirginiaDispensaryLocations;
