import React from 'react';
import useFetch from '../hooks/useFetch.js';

const Dispensaries = () => {
  const { data: dispensaries, loading, error } = useFetch('http://localhost/wp-json/wp/v2/dispensaries');

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error: {error.message}</p>;

  return (
    <div>
      <h1>Dispensaries</h1>
      {dispensaries.map(dispensary => (
        <div key={dispensary.id}>
          <h2>{dispensary.title.rendered}</h2>
          <div dangerouslySetInnerHTML={{ __html: dispensary.content.rendered }} />
        </div>
      ))}
    </div>
  );
};

export default Dispensaries;
