import React from 'react';
import ReactPlayer from 'react-player';
import './css/Section.css';

function Section({ title, description, mediaUrl, mediaType, isHighlighted, isReverse }) {
    const renderMedia = () => {
      if (mediaType === 'video') {
        return (
          <div className="media-wrapper">
            <ReactPlayer url={mediaUrl} className="media-circle" width="100%" height="100%" />
          </div>
        );
      } else {
        return <img src={mediaUrl} alt={title} className="media-circle" />;
      }
    };
  
    return (
      <section className={`section ${isHighlighted ? 'highlighted' : ''} ${isReverse ? 'reverse' : ''}`}>
        {renderMedia()}
        <div className="section-content">
          <h2>{title}</h2>
          <p>{description}</p>
        </div>
      </section>
    );
  }
  
  export default Section;