import React from 'react';
import Section from './Section';
import TrustpilotReviews from './TrustpilotReviews';
import Membership from './Membership';
import './css/AboutUs.css';

function About() {
  return (
    <div className="about-us">
      <h1>About Us</h1>
      {/* <h2>Proudly Serving Over 30,000 Patients</h2> */}
      <Section
        title={<h2>Proudly Serving Over 30,000 Patients</h2>}
        description={
          <span>
            CannabisMD TeleMed is an online platform that connects patients with healthcare providers to facilitate medical marijuana evaluations in Virginia. We have helped more than 30,000 Virginia residents receive convenient and affordable access to medical marijuana cards since opening our doors. We are proud to have a 5-star rating with reviews from patients, Google, Leafly, Facebook, TrustPilot, the Better Business Bureau, and more.
            <br /><br />
            We make the process of getting a Virginia medical cannabis card easy. Our streamlined, all-digital process allows patients to apply for their medical card in under 5 minutes and see a doctor the same day. 
            <br /><br />
            We offer a no-risk guarantee: if your recommendation for any reason is not approved, we will give you a follow-up appointment with another provider at no cost. If you do not receive a new medical marijuana physician recommendation for any reason you will receive a 100% refund.
          </span>
        }
        mediaUrl="https://youtu.be/Y3Xsv9ypauM"
        mediaType="video"
      />
      <section className="why-choose-us">
        <h2><span class="text-highlight-green">Why</span> Choose Us?</h2>
        <p>
          LeafRight has helped more than 30,000 Virginia residents receive convenient and affordable access to medical marijuana cards since opening its doors. We have hundreds of 5-star reviews across various platforms including Google, the Better Business Bureau, Leafly, and Yelp. If you value a few minutes and look at our reviews, you will see that patients love us for the simple, straightforward, and professional process when it comes to getting a Virginia medical cannabis card.
          <br /><br />
          Privacy is another advantage that comes with selecting LeafRight. We never share, sell, or disclose any of your information without your expressed written consent. All of the personal information you share with us stays with us and stays private.
          <br /><br />
          We offer an unbeatable no-risk guarantee: if your Virginia medical marijuana card application for any reason is not approved, we will give you a follow-up appointment with another provider at no cost.
        </p>
      </section>
      <TrustpilotReviews />
      <Membership />
      {/* <section className="customer-reviews">
        <h2>Customer Reviews</h2>
        <div className="review">
          <p><strong>Ernie K.</strong> - <i>Aug 23, 2023</i></p>
          <p>“You are comfortable from the beginning to the end. The whole process is simple and easy. The staff is great and knowledgeable. First class.”</p>
        </div>
        <div className="review">
          <p><strong>A.N.</strong> - <i>Aug 22, 2023</i></p>
          <p>“This process was so simple and quick! The staff is very knowledgeable and the doctor was great! I was approved instantly.”</p>
        </div>
        <div className="review">
          <p><strong>C.S.</strong> - <i>Aug 24, 2023</i></p>
          <p>“The entire process was quick, easy, and to the point. Highly recommend for anyone in need of medicine and would like a streamlined process.”</p>
        </div>
      </section> */}
    </div>
  );
}

export default About;