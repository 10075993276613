// TrustpilotReviews.js
import React, { useEffect } from 'react';
import Slider from 'react-slick';
import './css/TrustpilotReviews.css';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const reviews = [
  {
    initials: 'CU',
    name: 'Customer',
    location: 'US',
    reviewCount: 1,
    rating: 5,
    date: 'Jun 19, 2024',
    title: 'A very easy and straightforward process!',
    content: 'Easy instructions and paperwork process, helpful doctor, and very clear communication. What more could you ask for? This was smooth from start to finish!',
    url: 'https://www.trustpilot.com/review/cannabismdtelemed.com?sort=recency',
  },
  {
    initials: 'LH',
    name: 'L.H.L.',
    location: 'US',
    reviewCount: 1,
    rating: 5,
    date: 'Jun 19, 2024',
    title: 'Helpful, friendly',
    content: 'Helpful, friendly staff from the front door to the back counter. Thanks for all the help.',
    url: 'https://www.trustpilot.com/review/cannabismdtelemed.com?sort=recency',
  },
  {
    initials: 'CU',
    name: 'Customer',
    location: 'US',
    reviewCount: 1,
    rating: 5,
    date: 'Jun 19, 2024',
    title: 'Easy doctor to talk to',
    content: '',
    url: 'https://www.trustpilot.com/review/cannabismdtelemed.com?sort=recency',
  },
  {
    initials: 'CU',
    name: 'Customer',
    location: 'US',
    reviewCount: 1,
    rating: 5,
    date: 'Jun 19, 2024',
    title: 'Thank you',
    content: 'It was easy, comfortable, and professional. I felt heard and understood.',
    url: 'https://www.trustpilot.com/review/cannabismdtelemed.com?sort=recency',
  },
  {
    initials: 'CU',
    name: 'Customer',
    location: 'US',
    reviewCount: 1,
    rating: 5,
    date: 'Jun 19, 2024',
    title: 'Fast, friendly, and professional.',
    content: 'Really friendly and professional. The whole process was same day, quick and easy.',
    url: 'https://www.trustpilot.com/review/cannabismdtelemed.com?sort=recency',
  },
  {
    initials: 'R.K.',
    name: 'Rhonda Kesler',
    location: 'US',
    reviewCount: 4,
    rating: 5,
    date: 'Jun 18, 2024',
    title: 'Wesley was clear and informative',
    content: 'Wesley was clear and informative. Great patience with any questions . Calm',
    url: 'https://www.trustpilot.com/review/cannabismdtelemed.com?sort=recency',
  }
];

const TrustpilotReviews = () => {
    useEffect(() => {
        // Function to set equal heights for review cards
        const setEqualHeights = () => {
          const reviewCards = document.querySelectorAll('.review-card');
          let maxHeight = 0;
    
          // Reset heights to auto to recalculate
          reviewCards.forEach(card => card.style.height = 'auto');
    
          // Find the maximum height
          reviewCards.forEach(card => {
            maxHeight = Math.max(maxHeight, card.offsetHeight);
          });
    
          // Set all cards to the maximum height
          reviewCards.forEach(card => card.style.height = `${maxHeight}px`);
        };
    
        setEqualHeights(); // Initial call to set equal heights
        window.addEventListener('resize', setEqualHeights); // Recalculate on window resize
    
        return () => {
          window.removeEventListener('resize', setEqualHeights);
        };
      }, []);
    
      const sliderSettings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 1,
              infinite: true,
              dots: true
            }
          },
          {
            breakpoint: 768,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1
            }
          }
        ]
      };
  return (
    <div className="reviews-section">
      <img src="/images/half-ring.svg" alt="decorative half ring" className="half-ring filter-green" />
      <h2 className="reviews-heading">
        Real Reviews <span className="text-highlight-green">From</span> Real Patients
      </h2>
      {/* <div className="reviews-container">
        {reviews.map((review, index) => (
          <div className="review-card" key={index}>
            <div className="review-header">
              <div className="review-initials">{review.initials}</div>
              <div className="reviewer-info">
                <span className="reviewer-name">{review.name}</span>, <span className="reviewer-location">{review.location}</span>
                <div className="review-details">
                  {review.reviewCount} Reviews <span className="review-location">• {review.location}</span>
                </div>
              </div>
            </div>
            <div className="review-rating">
              {Array(review.rating).fill().map((_, i) => (
                <span key={i} className="star">★</span>
              ))}
              {Array(5 - review.rating).fill().map((_, i) => (
                <span key={i} className="star inactive">★</span>
              ))}
              <span className="verified">Verified</span> <span className="review-date">{review.date}</span>
            </div>
            <h3 className="review-title">{review.title}</h3>
            <p className="review-content">{review.content}</p>
          </div>
        ))}
      </div> */}
      <Slider {...sliderSettings}>
        {reviews.map((review, index) => (
          <div className="review-card" key={index}>
            <div className="review-header">
              <div className="review-initials">{review.initials}</div>
              <div className="reviewer-info">
                <span className="reviewer-name">{review.name}</span>, <span className="reviewer-location">{review.location}</span>
                <div className="review-details">
                  {review.reviewCount} Reviews <span className="review-location">• {review.location}</span>
                </div>
              </div>
            </div>
            <div className="review-rating">
              {Array(review.rating).fill().map((_, i) => (
                <span key={i} className="star">★</span>
              ))}
              {Array(5 - review.rating).fill().map((_, i) => (
                <span key={i} className="star inactive">★</span>
              ))}
              <span className="verified">Verified</span> <span className="review-date">{review.date}</span>
            </div>
            <h3 className="review-title">{review.title}</h3>
            <p className="review-content">{review.content}</p>
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default TrustpilotReviews;