import React, { useState } from 'react';
import './css/FAQPreview.css';

const FAQPreview = ({ faqData, moreQuestionsLink, filterTags }) => {
  const [activeIndex, setActiveIndex] = useState(null);

  const toggleFAQ = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  // Filter FAQ data based on the provided tags
  const filteredData = faqData.filter(item =>
    item.tags?.some(tag => filterTags.includes(tag))
  );

  return (
    <div className="faq-preview-section">
      <h2 className="faq-preview-title"><span className="text-highlight-green">Frequently</span> Asked Questions</h2>
      <div className="faq-preview-items">
        {filteredData.map((item, index) => (
          <div key={index} className="faq-preview-item" onClick={() => toggleFAQ(index)}>
            <div className="faq-preview-question">
              <span>{item.question}</span>
              <span className="faq-preview-toggle">{activeIndex === index ? '-' : '+'}</span>
            </div>
            {activeIndex === index && (
              <div className="faq-preview-answer">
                {item.answer}
              </div>
            )}
          </div>
        ))}
      </div>
      <div className="faq-more-questions">
        <p>More questions?</p>
        <a href={moreQuestionsLink}><button className="more-questions-button">Check out our FAQ page</button></a>
      </div>
    </div>
  );
};

export default FAQPreview;