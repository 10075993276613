import React, { useState } from 'react';
import './css/BenefitsSection.css';

const benefits = [
  {
    title: 'Effective Natural Treatment',
    content: [
        'One huge benefit of getting a medical marijuana card in Virginia is that it gives you access to the licensed dispensaries in Virginia and sometimes other states’ depending on those states reciprocity laws. This means that when you travel to other states, you may be able to continue using medical marijuana as an effective treatment for your medical condition.',
        'Access to licensed doctors and practitioners with knowledge about medical marijuana and how it can help treat your medical condition. Follow-up support at no additional cost so that you never feel like you do not have someone to call if you have questions or need guidance on your medical marijuana treatment in Virginia. All of the doctors and practitioners are licensed by the Commonwealth of Virginia and are always continuing their education when it comes to the benefits of medical marijuana and how it can naturally help patients find relief.'
      ]
  },
  {
    title: 'Dispensary Access',
    content: [
        'We always recommend that patients purchase their medical marijuana at a licensed dispensary in Virginia. The only way to get access to a dispensary in Virginia is if you have a Virginia medical marijuana card. The dispensaries carry more than just flower marijuana products; they also carry vapes, tinctures, edibles, lotions, concentrates, as well as other medical marijuana products.'
      ]
  },
  {
    title: 'Legal Protection',
    content: [
        'With the new marijuana laws that went into effect on July 1, 2021, patients that have a written medical marijuana certification from a licensed doctor receive legal protections, as well as an easy way to demonstrate to law enforcement that they are using marijuana as a medical treatment option recommended by your doctor. According to the Virginia Medical Marijuana Control Program, this allows patients to possess, transport, and consume medical cannabis products.',
        'Medical marijuana patients are allowed to possess other forms of marijuana besides just the flower product. They are also allowed to possess a higher limit of medical marijuana than what is allowed by recreational users.'
      ]
  },
  {
    title: 'Employee Protections',
    content: [
        'Part of the marijuana legislation that went into effect on July 1, 2021, also gave certain protections to employees who use medical marijuana during their off-hours to treat their medical condition as long as they have a valid written medical cannabis certificate. New employee protection prevents employers from taking punitive, disciplinary, or other actions such as termination for an employee testing positive for THC when they have a Virginia medical marijuana card and a valid written medical cannabis certificate. These laws do not allow employees to use their medical marijuana on the job site, and it does not allow employees to be under the influence of marijuana while at work. These employee protections do not provide protection for all jobs such as driving, safety supervisors, federal employees, and a few other special circumstances, but the majority of Virginia residents should be able to receive the employee protections. You can learn more about the medical marijuana employee protection bill here.'
      ]
  },
  {
    title: 'Product Confidence for Virginia Patients',
    content: [
        'The medical marijuana dispensaries in Virginia carry all types of medical marijuana products, including but not limited to flower cannabis, edibles, oils, vapes, patches, tinctures, lotions, concentrates, extracts, and lozenges. Since these are state-licensed medical marijuana dispensaries, their products must list exactly what is in each product so that patients know what they are putting in their bodies, this gives patients the ability to get consistent access to quality medical marijuana products on a regular basis. Virginia residents who have a Virginia MMJ Card will be able to go to the dispensary and purchase any of the products they feel could help treat their medical condition.',
        'The peace of mind that comes from knowing what is in your product is another huge benefit to patients. All of the medical cannabis products at Virginia dispensaries are independently tested by a third-party lab to ensure that the label on the product is accurate and patients know exactly what they are putting into their bodies.',
        'We encourage patients to continually check the menus at the Virginia marijuana dispensaries because they are always adding new products and formulations to better serve patients who feel medical cannabis is the best natural treatment option for them.'
      ]
  },
  {
    title: 'Access To Other States',
    content: [
        'Many states of medical marijuana reciprocity laws, which means when you travel to another state that has a medical marijuana program, you may be able to use your Virginia medical marijuana card to continue purchasing medical cannabis products to alleviate the symptoms of your medical condition even on vacation. This is another huge benefit of getting your Virginia medical marijuana card!',
        'We always advise patients to check with the dispensary they plan on visiting to see if they have reciprocity laws that will accept a Virginia medical marijuana card.'
      ]
  }
];

const BenefitsSection = () => {
    const [activeIndex, setActiveIndex] = useState(null);
  
    const toggleItem = (index) => {
      setActiveIndex(activeIndex === index ? null : index);
    };
  
    return (
      <div className="benefits-section">
        <h2 className="benefits-heading">
          Now That Legalized Adult-Use Marijuana is in Virginia, Why Should You Get a Virginia Medical Marijuana Card in 2022?
        </h2>
        <div className="benefits-container">
          <div className="content-column">
            <p>
              Starting July 1, 2021, adult-use marijuana legislation went into effect, which reduced or eliminated criminal penalties for possession of marijuana up to a certain amount for adults 21 and older. Also, this legislation allowed for the home cultivation of marijuana plants for adults 21 and older. Due to this new marijuana legislation, we have many patients that want to know if there are any benefits to having a medical marijuana card in Virginia in 2022. One of the main benefits is that it allows patients to purchase marijuana directly from the dispensary. Recreational sale of marijuana will not begin until 2024, and with new leadership in Virginia, the recreational sale of marijuana may be pushed back even further.
            </p>
            <p>
              The Virginia Cannabis Authority has said they would like to move the recreational sales date of marijuana up to 2023, but so far, that does not seem likely. Even when recreational sales of marijuana begin, you will receive preferential treatment if you have a medical marijuana card because you have documentation proving you are using marijuana for medical reasons, which means if there is a shortage of medical cannabis, it will default to medical patients before recreational marijuana users. Medical marijuana will also not have the 21% excise tax that is found when purchasing medical marijuana recreationally without a medical marijuana card.
            </p>
            <p>
              At CannabisMD TeleMed, we offer the most affordable pricing when it comes to getting your medical marijuana recommendation by a licensed marijuana doctor. Our prices are usually half the cost of many other companies, and we offer personal one-on-one support to each and every patient who requires it. With our inexpensive pricing for medical marijuana certification evaluations, there is no need to wait for recreational sales to begin to start treating your medical conditions or symptoms with cannabis. Read below to see some of the benefits of choosing CannabisMD TeleMed and getting your Virginia medical marijuana card.
            </p>
          </div>
          <div className="sidebar-column">
            {benefits.map((benefit, index) => (
              <div key={index} className={`benefit-item ${activeIndex === index ? 'active' : ''}`}>
                <div className="benefit-title" onClick={() => toggleItem(index)}>
                  {benefit.title} <span className="toggle-icon">{activeIndex === index ? '−' : '+'}</span>
                </div>
                {activeIndex === index && (
                  <div className="benefit-content">
                    {benefit.content.map((paragraph, i) => (
                      <p key={i}>{paragraph}</p>
                    ))}
                  </div>
                )}
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  };
  
  export default BenefitsSection;