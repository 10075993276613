// Steps.js
import React from 'react';
import './css/Steps.css';

const steps = [
  {
    imgSrc: '/images/works01.svg',
    alt: 'Schedule Appointment',
    description: 'Schedule a medical marijuana evaluation online, or call us at ',
    phoneNumber: '(804) 893-5068',
  },
  {
    imgSrc: '/images/works02.svg',
    alt: 'Submit Forms',
    description: 'Submit your patient forms through our online patient portal.',
  },
  {
    imgSrc: '/images/works03.svg',
    alt: 'Talk to Doctor',
    description: 'Talk To A Licensed Doctor Using Telemedicine Via Your Phone, Tablet, Or Computer. Get Approved.',
  },
  {
    imgSrc: '/images/works04.svg',
    alt: 'Receive Certificate',
    description: 'Take your medical marijuana certificate to any dispensary and purchase products.',
  },
];

const Steps = ({ titleText, closingText, buttonText, buttonLink }) => {
  return (
    <div className="how-it-works-section">
      <h3 className="how-it-works-title">{titleText}</h3>
      <h2 className="how-it-works-subtitle">Get Your Medical Marijuana Card Online From Anywhere</h2>
      <div className="how-it-works-steps">
        {steps.map((step, index) => (
          <div className="how-it-works-step" key={index}>
            <img src={step.imgSrc} alt={step.alt} className="how-it-works-image" />
            <p className="how-it-works-description">{step.description} {step.phoneNumber && 
            <a href={`tel:${step.phoneNumber.replace(/\D/g, '')}`}>{step.phoneNumber}</a>
          }</p>
          </div>
        ))}
      </div>
      
      <h3 className='how-it-works-closing'>{closingText}</h3>
      <a href={buttonLink}><button className="ctabutton">{buttonText}</button></a>
    </div>
  );
};

export default Steps;