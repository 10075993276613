import React from 'react';
import Section from './Section';
import CTA from './CTA';
import Membership from './Membership';
import './css/HowItWorks.css';

function HowItWorks() {
  return (
    <div className="how-it-works">
      <h1>How It Works</h1>
      <Section
        title={<h2><span class="text-highlight-green">Schedule</span> Your Appointment.</h2>}
        description={<span>This video will walk you through the process of scheduling an appointment for a Medical Cannabis Certification with a licensed marijuana practitioner at CannabisMD TeleMed.</span>}
        mediaUrl="https://youtu.be/Y3Xsv9ypauM"
        mediaType="video"
      />
      <Section
        title={<h2>Submit Your Information</h2>}
        description={<span>Here is a video walkthrough of how to fill out your online medical intake forms prior to your appointment with a cannabis doctor. This appointment is with a licensed medical provider to receive your medical marijuana certification so you can receive your medical marijuana card (MMJ Card) and begin purchasing medical marijuana from any dispensary in Virginia.</span>}
        mediaUrl="https://youtu.be/ivkVrvKucl4"
        mediaType="video"
        isReverse
      />
      <Section
        title={<h2><span class="text-highlight-green">Talk To A </span>Marijuana Doctor</h2>}
        description={<span>This video shows patients how to enter the virtual online appointment using telemedicine to see a licensed marijuana doctor at CannabisMD TeleMed.</span>}
        mediaUrl="https://youtu.be/aMoGRW4-ep8"
        mediaType="video"
      />
      <Section
        title={<h2><span class="text-highlight-green">Take Your Certificate</span> To The Dispensary!</h2>}
        description={<span>After you receive your <strong> medical cannabis certification by email </strong> following your appointment you can <strong>immediately</strong> go to any medical marijuana dispensary location in Virginia. <br/> This video will show you how to access your medical marijuana certificate after your appointment with the provider.</span>}
        mediaUrl="https://youtu.be/bCOa3B1lvIc"
        mediaType="video"
        isReverse
      />
      <CTA />
      <Membership />
    </div>
  );
}

export default HowItWorks;