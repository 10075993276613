import React, { useState } from 'react';
import './css/ContactUs.css';
import Membership from './Membership';


const offices = [
  {
    city: 'Richmond',
    addressLine1: '1230 Alverser Drive, Suite 103',
    addressLine2: 'Midlothian, VA 23113',
    email: 'hello@cannabismdtelemed.com',
    phone: '(804) 893-5068',
    phoneLink: '+18048935068',
    fax: '(804) 728-0546'
  },
  {
    city: 'Fairfax',
    addressLine1: '10304 Eaton Place, Suite 100',
    addressLine2: 'Fairfax, VA 22030',
    email: 'hello@cannabismdtelemed.com',
    phone: '(703) 996-4803',
    phoneLink: '+17039964803',
    fax: '(804) 728-0546'
  },
  {
    city: 'Norfolk',
    addressLine1: '500 E Main St, FL 16',
    addressLine2: 'Norfolk, VA 23510',
    email: 'hello@cannabismdtelemed.com',
    phone: '(757) 702-0116',
    phoneLink: '+17577020116',
    fax: '(804) 728-0546'
  },
  {
    city: 'Arlington',
    addressLine1: '2300 Wilson Blvd, Suite 700',
    addressLine2: 'Arlington, VA 22201',
    email: 'hello@cannabismdtelemed.com',
    phone: '(571) 262-0804',
    phoneLink: '+15712620804',
    fax: '(804) 728-0546'
  }
];



function ContactUs() {
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    message: ''
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Handle form submission logic here
    console.log('Form data:', formData);
  };

  return (
    <div className="contact-us">
      <div className='location-section'>
      <h1>Contact Us</h1>
      <div className="office-locations">
        {offices.map((office, index) => (
          <div key={index} className="office">
            <h2 className="office-city">{office.city} Office</h2>
            <p className="office-address">{office.addressLine1}<br />{office.addressLine2}</p>
            <p className="office-contact">
              {/* <strong>Email:</strong> <a href={`mailto:${office.email}`}>{office.email}</a><br /> */}
              <strong>Office:</strong> <a href={`tel:${office.phoneLink}`}>{office.phone}</a><br />
              <strong>Fax:</strong> {office.fax}
            </p>
          </div>
        ))}
      </div>
      <p className='email-contact'>Email us at: <a href="mailto:hello@cannabismdtelemed.com">hello@cannabismdtelemed.com</a></p>
      </div>
      <div className="cta-dots" id="dotsone"></div>
      <form className="contact-form" onSubmit={handleSubmit}>
        
      <div className="form-group">
        <input
          type="text"
          name="firstName"
          value={formData.firstName}
          onChange={handleChange}
          placeholder="First Name*"
          required
        />
        <input
          type="text"
          name="lastName"
          value={formData.lastName}
          onChange={handleChange}
          placeholder="Last Name*"
          required
        />
      </div>
      <div className="form-group">
        <input
          type="email"
          name="email"
          value={formData.email}
          onChange={handleChange}
          placeholder="Email*"
          required
        />
        <input
          type="tel"
          name="phone"
          value={formData.phone}
          onChange={handleChange}
          placeholder="Phone Number*"
          required
        />
      </div>
      <div className="form-group">
        <textarea
          name="message"
          value={formData.message}
          onChange={handleChange}
          placeholder="Message*"
          required
        />
      </div>
      <div className='button-wrapper'>
      <div className="cta-dots" id="dotstwo"></div>
      <button type="submit" className="submit-button">Send Message</button>
      </div>
    </form>
      
      <Membership />
    </div>
  );
}

export default ContactUs;