import React, { useState, useEffect } from 'react';
import axios from 'axios';

function Header() {
  return (
    <header className='navmenu'>
        <div className="logo">
            <a href="/">
                {/* <img src="/images/cmd-secondary-lightbg.png" alt="Logo" /> */}
                <img src="/images/headerroboto.png" alt="Logo" />
            </a>
        </div>
        <nav>
          <a href="/about">About</a>
          <a href="/how-it-works">How It Works</a>
          <a href="/how-to-qualify">How To Qualify</a>
          <div className="dropdown">
              <button className="dropbtn">Dispensaries</button>
              <div className="dropdown-content">
                  <a href="/virginia-dispensary-locations">Virginia</a>
              </div>
          </div>
          <div className="dropdown">
              <button className="dropbtn">Resources</button>
              <div className="dropdown-content">
                  <a href="/faq">FAQ</a>
                  <a href="/blog">Blog</a>
                  <a href="/conditions">Conditions</a>
                  <a href="/cannabis-strains">Cannabis Education</a>
              </div>
          </div>
          <a href="/contact-us">Contact Us</a>
          <a className="ctabtn" href="https://cannabismdtelemed.com/appointments">Book An Appointment</a>
      </nav>
    </header>
  );
}

export default Header;
