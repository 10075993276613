// WhyChooseUs.js
import React from 'react';
import './css/WhyChooseUs.css';

const features = [
  {
    imgSrc: '/images/charity.svg',
    alt: 'Charity Pledge',
    title: '1% Charity Pledge',
    description: 'We Give Back To The Community We Serve, 1% Of Our Company’s Profit Goes To Help Local Charities. This Year We Gave To REACHcycles And Veterans & Athletes United.',
  },
  {
    imgSrc: '/images/convenient.svg',
    alt: 'Patient Satisfaction',
    title: 'Patient Satisfaction',
    description: 'We Have 5-Star Ratings On Google And The Better Business Bureau Across All Our Locations Because We Help Patients Get Their Medical Marijuana Card.',
  },
  {
    imgSrc: '/images/satisfaction.svg',
    alt: 'Convenient and Affordable',
    title: 'Convenient and Affordable',
    description: 'Best Pricing With Virtual Appointments Conducted Entirely Online So You Can Get Approved For A Medical Marijuana Card Faster.',
  },
  {
    imgSrc: '/images/money-back.svg',
    alt: 'Money-Back Guarantee',
    title: 'Money-Back Guarantee',
    description: 'We Offer A 100% Money-Back Guarantee If You Are Not Approved. Over 99% Of Patients Are Approved For A Medical Marijuana Card. All Medical Conditions Are Considered!',
  },
];

const WhyChooseUs = ({buttonText, buttonLink}) => {
  return (
    <div className="why-choose-us-section">
      <div className="why-choose-us-content">
        <h2 className="why-choose-us-title"><span class="text-highlight-green">Why</span> Choose Us?</h2>
        <p className="why-choose-us-description">
          CannabisMD TeleMed is an online platform that connects patients with healthcare providers to facilitate medical marijuana evaluations in Virginia. We have helped more than 35,000 Virginia residents receive convenient and affordable access to medical marijuana cards since opening our doors. We have over 5,000 of 5-star verified reviews across multiple platforms including Google, Trustpilot, The Better Business Bureau, Leafly, Yelp, and Facebook. If you take a few minutes and look over our reviews you will see that patients feel we offer the most simple, affordable, and professional process when it comes to getting a Virginia medical cannabis card.
        </p>
        <a href={buttonLink}><button className="cta-button">{buttonText}</button></a>
      </div>
      <div className="why-choose-us-features">
        {features.map((feature, index) => (
          <div className="feature-card" key={index}>
            <img src={feature.imgSrc} alt={feature.alt} className="feature-image" />
            <h3 className="feature-title">{feature.title}</h3>
            <p className="feature-description">{feature.description}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default WhyChooseUs;