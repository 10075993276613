// BenefitsCTA.js
import React from 'react';
import './css/BenefitsCTA.css';

const benefits = [
  {
    icon: '/images/icon_legal.png',
    title: 'Legal Access to Marijuana',
    description: 'The only legal way to purchase marijuana is at medical marijuana dispensaries and you must have a medical cannabis certificate to enter and purchase at Virginia dispensary locations.'
  },
  {
    icon: '/images/icon_saving.png',
    title: 'Save Money',
    description: 'If recreational sales of marijuana come to Virginia, you will not have to pay the 21% retail sales tax when purchasing cannabis with your medical marijuana card.'
  },
  {
    icon: '/images/icon_employeeprotect.png',
    title: 'Employee Protections',
    description: 'In most cases (with some exceptions) it is now illegal for most Virginia companies to take punitive action against employees who test positive for THC if they have a Virginia marijuana card. This is thanks to HB 1862 which was approved by Governor Northam.'
  }
];

const BenefitsCTA = () => {
  return (
    <div className="benefits-cta-section">
      <h2 className="benefits-heading">Benefits of Virginia Medical Marijuana Card</h2>
      <div className="benefits-container">
        {benefits.map((benefit, index) => (
          <div className="benefit-card" key={index}>
            <img src={benefit.icon} alt={benefit.title} className="benefit-icon" />
            <h3 className="benefit-title">{benefit.title}</h3>
            <p className="benefit-description">{benefit.description}</p>
          </div>
        ))}
      </div>
      <button className="cta-button">Schedule Now</button>
    </div>
  );
};

export default BenefitsCTA;