import React from 'react';
import './css/Stats.css'
const Stats = () => {
  return (
    <div className="stats-section">
      <div className="half-ring-wrapper">
        <img src="/images/half-ring.svg" alt="Half Ring" className="half-ring" />
      </div>
      <div className="stat-item">
        <h1 className="stat-number">1K+</h1>
        <p className="stat-description">Over 1000 Studies</p>
      </div>
      <div className="stat-item">
        <h1 className="stat-number">50K+</h1>
        <p className="stat-description">Patients Enrolled</p>
      </div>
      <div className="stat-item">
        <h1 className="stat-number">200+</h1>
        <p className="stat-description">Medical Conditions</p>
      </div>
    </div>
  );
};
//   return (
//     <div className="stats-section">
//       <div className="stat-item">
//           <h1 className="stat-number">1K+</h1>
//           <p className="stat-description">Over 1000 Studies</p>
//       </div>
//       <div className="stat-item">
//           <h1 className="stat-number">20K+</h1>
//           <p className="stat-description">Patients Enrolled</p>
//       </div>
//       <div className="stat-item">
//           <h1 className="stat-number">100+</h1>
//           <p className="stat-description">Medical Conditions</p>
//       </div>
//     </div>
//   );
// };

export default Stats;