import React from 'react';
import Steps from './Steps';
import FAQPreview from './FAQPreview';
import { FAQData } from './FAQ';
import Membership from './Membership';
import TrustpilotReviews from './TrustpilotReviews';
import './css/HowToQualify.css';
import BenefitsCTA from './BenefitsCTA';
import WhyChooseUs from './WhyChooseUs';
import FindDoctorSection from './FindDoctorSection';
import BenefitsSection from './BenefitsSection';

function HowToQualify() {
  return (
    <div className="how-to-qualify">
        <Steps 
                titleText={<h1>How to Qualify</h1>}
                buttonText="Schedule Your Online Appointment Today" 
                buttonLink="https://cannabismdtelemed.com/appointments" 
            />
        <TrustpilotReviews />
        <BenefitsCTA />
        <WhyChooseUs 
            buttonText="Get Your Marijuana Card" 
            buttonLink="https://cannabismdtelemed.com/appointments" />
        <FAQPreview faqData={FAQData} filterTags={['howtoqualify']} moreQuestionsLink="/faq" />
        <BenefitsSection />
        <FindDoctorSection style={{ maxWidth: '100%', width: '100%' }}/>
        <section class="get-approved">
            <h2>Get Approved for Medical Marijuana and Receive Your Virginia Marijuana Card to Get Access to Virginia Marijuana Dispensaries Today!</h2>
            <p>If you are ready to explore the numerous natural benefits of medical marijuana, then it is time for your to get your Virginia medical marijuana card so you can begin purchasing cannabis products in person or online from the comfort of your home.</p>
            <p>At CannabisMD TeleMed, our intuitive process makes the entire journey of getting your medical marijuana card in VA simple and easy. Our support staff is standing by seven days a week to assist patients, answer questions, and provide other support at no additional cost!</p>
            <p>Sign up today to schedule your medical marijuana evaluation with a licensed marijuana doctor at CannabisMD TeleMed. Get approved to use medical marijuana so you can receive your MMJ Card in VA.</p>
            <p><a href="https://cannabismdtelemed.com/appointments">Schedule your appointment online</a> or call us at <a href='tel:+18048935068'>(804) 893-5068</a> if you have any questions about the process or need help scheduling your virtual appointment to get approved for medical cannabis treatment in Virginia.</p>
            <div className="button-wrapper"><a href="https://cannabismdtelemed.com/appointments"><button class="cta-button">Book An Appointment</button></a></div>
        </section>
        <Membership />
    </div>
  );
}

export default HowToQualify;