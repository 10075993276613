import React from 'react';
import Select from 'react-select';
import './css/HeroComponent.css'

const options = [
  { value: 'Virginia', label: 'Virginia' }, 
  { value: 'Test', label: 'Test' }, 
  { value: 'Test1', label: 'Test' }, 
  { value: 'Test2', label: 'Test' }, 
  { value: 'Test3', label: 'Test' }, 
  { value: 'Test4', label: 'Test' }, 
  { value: 'Test5', label: 'Test' }, 
  { value: 'Test6', label: 'Test' }, 
  { value: 'Test7', label: 'Test' }, 
  { value: 'Test8', label: 'Test' }, 
  { value: 'Test9', label: 'Test' }, 
  { value: 'Test10', label: 'Test' }, 
  { value: 'Test11', label: 'Test' }, 
  { value: 'Test12', label: 'Test' }, 
  { value: 'Test13', label: 'Test' }, 
  { value: 'Test14', label: 'Test' }, 
  { value: 'Test15', label: 'Test' }, 
  { value: 'Test16', label: 'Test' }, 
  { value: 'Test17', label: 'Test' }, 
  { value: 'Test18', label: 'Test' }
];

const customStyles = {
  control: (provided, state) => ({
    ...provided,
    width: '200px',
    backgroundColor: '#fff',
    border: state.isFocused ? '1px solid #08f784' : '1px solid #384e68',
    borderRadius: '20px',
    boxShadow: state.isFocused ? '0 0 5px rgba(8, 247, 132, 0.5)' : 'none',
    '&:hover': {
      borderColor: '#08f784',
    },
    cursor: 'pointer',
  }),
  singleValue: (provided) => ({
    ...provided,
    color: '#384e68',
    textTransform: 'uppercase',
  }),
  dropdownIndicator: (provided) => ({
    ...provided,
    color: '#384e68',
    '&:hover': {
      color: '#08f784',
    },
  }),
  indicatorSeparator: () => ({
    display: 'none',
  }),
  menu: (provided) => ({
    ...provided,
    backgroundColor: '#fff',
    border: '1px solid #384e68',
    borderRadius: '20px',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
    marginTop: '5px',
    maxHeight: '200px',
    overflowY: 'auto',
  }),
  menuList: (provided) => ({
    ...provided,
    padding: '0 0 15px 0',
    borderRadius: '20px',
  }),
  option: (provided, state) => ({
    ...provided,
    padding: '10px 20px',
    // color: state.isSelected ? '#fff' : '#384e68',
    color: '#384e68',
    backgroundColor: state.isSelected ? '#4ac9f3' : '#fff',
    '&:hover': {
      backgroundColor: state.isSelected ? '#4ac9f3' : '#f0f0f0',
    },
    cursor: 'pointer',
  }),
  placeholder: (provided) => ({
    ...provided,
    color: '#384e68',
    textTransform: 'uppercase',
  }),
};


const HeroSection = () => {
  return (
      <div className="hero-section">
        <div className="green-cloud"></div>
          <div className="hero-content">
              <h1 className="welcome-text">WELCOME</h1>
              <h2 className="main-heading">Get Your Medical Marijuana Card Online In Minutes.</h2>
              <div className="state-selection">
              <Select options={options} styles={customStyles} className="state-dropdown" placeholder="Select State" />
                  <a href="https://cannabismdtelemed.com/appointments"><button className="get-card-button">Get Your Card</button></a>
              </div>
              <div className="hero-benefits">
                  <div className="hero-benefit-item">
                    <img className="check-icon" src="/images/tick.png" alt="icon-1" />
                    Affordable And Simple Pricing. Get Approved Or Your Money Back.
                  </div>
                  <div className="hero-benefit-item">
                    <img className="check-icon" src="/images/tick.png" alt="icon-1" />
                    Our Platform Connects You To A Network Of Doctors To Get Certified In Minutes.
                  </div>
              </div>
          </div>
          <div className="hero-image">
              <img src={'/images/banner-hand.png'} alt="Medical Card" />
          </div>
      </div>
  );
};

export default HeroSection;